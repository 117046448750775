






















































































import Vue, { PropType } from "vue";
import RecipeStats from "../Models/RecipeStats";
import RecipeIngredient from "../Models/RecipeIngredient";
import { mockRecipeStats, mockRecipeIngredient } from "@/services/mockService";
import { timeSince } from "@/services/timeServices";

export default Vue.extend({
  name: "RecipeToolTip",
  props: {
    statsstring: {
      type: String
    }
  },
  computed: {
    HQTotal: function() {
      if (!this.recipeStats) return 0;
      const vm = this as any;
      return (vm.recipeStats as RecipeStats).Ingredients.map(e => {
        const price = e.HQPrice === 0 ? e.NQPrice : e.HQPrice;
        return price * e.Quantity;
      }).reduce((total, i) => i + total);
    },
    NQTotal: function() {
      if (!this.recipeStats) return 0;
      const vm = this as any;
      return (vm.recipeStats as RecipeStats).Ingredients.map(e => {
        const price = e.NQPrice === 0 ? e.HQPrice : e.NQPrice;
        return price * e.Quantity;
      }).reduce((total, i) => i + total);
    },
    recipeStats: function(): RecipeStats | null {
      try {
        return JSON.parse(this.statsstring) as RecipeStats;
      } catch {
        return null;
      }
    }
  },
  methods: {
    dateToString(date: Date) {
      return timeSince(date);
    }
  }
});
